<template>

    <b-button-group style="border: 1px solid gray; padding: 1px; background-color: #e7edf5; color: black; font-weight: bold; border-radius: 3px;"> 
        <span style="padding-top: 5px; margin-right: 4px; margin-left: 2px"> seasonals: </span> 
        <b-button-group  v-for="n in seasonalOptions" :key="n.value">
            <seasonal-toggle v-bind:year="n.value" v-bind:namespace="namespace" style="margin-right: 1px"></seasonal-toggle>
        </b-button-group>
    </b-button-group>

</template>

<script>
    import seasonalToggle from '@/components/seasonal-toggle.vue';

    export default {
        props: ['namespace'],
        computed: {
            seasonalOptions: {
                get() {
                    return this.$store.getters[this.namespace + '/seasonalOptions'];
                }
            }
        },
        components: {
            seasonalToggle
        }
    }
</script>
